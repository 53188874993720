<script>
    import ItemCard from '../products/particles/ItemCard.svelte';

    export let list = [];
    export let rounded = true;
    export let gap = '16px';
    export let share = false;
</script>
<section 
    class="catalog_grid"
    style:gap
>
    {#each list as item, index (item.id)}
        {#if !(share && item.unsharable)}
            {@const last = (
                list.length - 3 < index &&
                item.tile != 'default'
            )}
            <ItemCard
                isLast={last}
                {index}
                {rounded}
                {item}
            />
            <!-- <a 
                href="{localeRoute}/{link}" 
                class="product tile_{tile}" 
                class:last
                class:rounded 
                style:order={sort}
                in:fade={{delay:(index+1)*100, duration:700, easing:quadOut}}
            >
                <div class="figure">
                    {#if video}
                        <video src={video} muted loop autoplay></video>
                    {:else}
                        <img src={img} alt={name} loading="lazy">
                    {/if}
                </div>
                <p>
                    {name}
                    {#if tile != "small"}
                        {@html arrow}
                    {/if}
                </p>
            </a> -->
        {/if}
    {/each}
</section>

<style lang="postcss">
    /* @media (min-width:375px){
        .catalog_grid{
            margin: * * -16px;
            display: flex;
            flex-wrap: wrap;
        }
    }
    .catalog_grid{
        gap: 16px;
        &::after {
            content: '';
            flex: 100% 0 0;
            order: 10000;
        }
    } */
    /* .figure{
        overflow: hidden;
        position: relative;
        &{
            @media (min-width:992px) {
                flex: 78% 0 0;
            }
        }
    }
    video, img{
        z-index: 1;
        position: absolute 0;
    }
    p{
        margin: 0;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: 600; 
        line-height: 1.4;
        flex: 20% 1 1;
        min-height: 64px;
        &{
            @media (min-width:992px) {
                flex: 22% 1 1;
            }
        }
        & > :global(svg){
            transition: .3s ease-out;
            margin: * * * 16px;
            @media (min-width:992px) {
                margin: * * * 36px;
                min-height: 80px;
            }
        }
    } */
</style>