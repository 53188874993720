<script>
    export let features = undefined;
</script>

{#if features}
{@const {left, right, ice} = features}
    <div class="card_features_wrap">
        {#if left}
            <!-- <div class="card_feature top_left">
                {#if left.top}
                    {#each left.top as text}
                        <span class="label">{text}</span>
                    {/each}
                {/if}
            </div> -->
            <div class="card_feature bottom_left">
                {#if left.bottom}
                    {#each left.bottom as text}
                        <span class="label">{text}</span>
                    {/each}
                {/if}
            </div>
        {/if}
        {#if ice}
        <!-- right -->
            <div class="card_feature top_right">
                <!-- {#if right.top}
                    {#each right.top as text}
                        <span class="label">{text}</span>
                    {/each}
                {/if} -->
                {#if ice}
                    <svg class="ice" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none">
                        <path fill="#9B9B9B" d="M12.6 25h-.2c-.6-.1-1-.5-1-1.2V22l-1.3.8c-.2.2-.5.2-.8.2a1 1 0 0 1-.8-.9c-.1-.4.1-.9.5-1.1l2.3-1.3.2-.3v-5h-.1A1990.5 1990.5 0 0 1 6.9 17 232.6 232.6 0 0 1 7 20c0 .4-.5 1-1.2.9a1 1 0 0 1-1-1.1v-1.6l-1.5 1-.8.1a1 1 0 0 1-.8-.8c-.2-.4 0-.8.3-1l1-.7.8-.5-1.3-.7c-.5-.3-.7-.7-.6-1.2.2-.7 1-1 1.7-.7L5.7 15H6l3.9-2.2.4-.3-.7-.4L6 10h-.3l-2.2 1.3c-.2.1-.5.2-.8.1a1 1 0 0 1-.8-.8c0-.4 0-.8.4-1L3 9a60.9 60.9 0 0 1 .5-.5l-1.4-.8a1 1 0 0 1-.4-1.4c.2-.5.8-.8 1.3-.5l1 .4.8.6V5.2a1 1 0 0 1 1.3-1c.5 0 .8.5.8 1V8l.1.2 3.7 2 .8.5V5.5l-.1-.1-.8-.5-1.7-1a1 1 0 0 1-.4-1c.2-.8 1-1.1 1.6-.7l.8.4.6.3V1.2c0-.6.3-1 .8-1.1H12.6c.6 0 1 .5 1 1v1.8h.1l1.2-.7c.3-.2.6-.3 1-.2.3.2.6.5.7 1 0 .4-.2.8-.6 1a579.8 579.8 0 0 1-2.4 1.5v5.1l.4-.2a2820.5 2820.5 0 0 1 4.1-2.5 226 226 0 0 1 .1-3.1 1 1 0 0 1 1.2-.7c.5.1.8.5.8 1v1.7h.2l1.4-1a1 1 0 0 1 1.6.9c.1.3-.1.8-.5 1l-.9.6-.7.3 1.5.9c.3.2.5.5.4 1a1 1 0 0 1-1.6.8 561.4 561.4 0 0 1-2.5-1.4l-3.5 2-1 .6.8.5 3.6 2 .2.1 2.5-1.4c.2-.2.5-.2.8 0 .4 0 .7.4.7.8 0 .5-.1.8-.5 1l-.7.5-.7.4 1.6 1c.4.1.6.5.6.9a1 1 0 0 1-1.6 1l-1-.7-.6-.3v1.6c-.1.6-.6 1-1.2 1a1 1 0 0 1-1-1V17l-.8-.4-3.5-2-.2-.2a1742.4 1742.4 0 0 0 0 5.2l1.1.6 1.4.8c.5.3.6.9.4 1.4a1 1 0 0 1-1.3.5l-.7-.4-1-.5v2c0 .3 0 .6-.3.8l-.6.2Z"/>
                        <path fill="#B1B1B1" d="M23.5 18.3a1 1 0 0 1-1.6 1l-1-.7-.6-.3v1.6a1 1 0 0 1-2.2 0V17l-.8-.5-3.5-2-.1-.1-2.4-3.9.1.1V5.4l-.8-.5-1.7-1a1 1 0 0 1-.4-1c.2-.8 1-1.1 1.6-.7l.8.4.6.3V1.2c0-.6.3-1 .8-1.1H12.6c.6 0 1 .5 1 1v1.8h.1l1.2-.7c.3-.2.6-.3.9-.2.4.2.7.5.8 1 0 .4-.2.8-.6 1l-2.3 1.4-.1.1v5.1l.4-.2 4-2.3.1-.2a226 226 0 0 1 .1-3.1 1 1 0 0 1 1.2-.7c.4.1.8.5.8 1v1.7h.2l1.4-1a1 1 0 0 1 1.6.9c0 .3-.1.8-.5 1l-1 .6-.6.3 1.4.9c.4.2.6.5.5 1a1 1 0 0 1-1.6.8 574.9 574.9 0 0 1-2.5-1.4l-3.5 2-1 .6.8.5 3.6 2 .2.1 2.5-1.4c.2-.2.5-.2.8 0 .4 0 .7.4.7.8 0 .5-.1.8-.5 1l-.7.5-.7.4 1.6 1c.4.1.6.5.6.9Z"/>
                    </svg>                  
                {/if}
            </div>
            <!-- <div class="card_feature bottom_right">
                {#if right.bottom}
                    {#each right.bottom as text}
                        <span class="label">{text}</span>
                    {/each}
                {/if}
            </div> -->
        {/if}
    </div>
{/if}

<style>
    .card_features_wrap{
        display: grid;
        grid-template: 1fr 1fr / 1fr 1fr;
        grid-template-areas: 'tl tr' 'bl br';
        height: 100%;
        padding: * * 8px;
    }
    /* .top_left{
        grid-area: tl;
    } */
    .top_right{
        grid-area: tr;
        justify-self: end;
    }
    .bottom_left{
        grid-area: bl;
        font-size: 12px;
        align-self: end;
    }
    /* .bottom_right{
        grid-area: br;
        align-self: end;
        justify-self: end;
    } */
    .label{
        /* background: var(--bg); */
        background: #000;
        padding: 8px 16px;
        line-height: 1;
        border-radius: 30px;
        margin: 8px 8px *;
    }
    .card_feature{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .ice{
        margin: 10px 10px * *;
    }
</style>
<!-- {#each ['left', 'right'] as col}
    {#if features[col]}
        {#each ['top', 'bottom'] as row}
            {#if features[col][row]}
                <div class="{row}_{col}">
                    {#each features[col][row] as text}
                        <span>{text}</span>
                    {/each}
                </div>
            {/if}
        {/each}
    {/if}
{/each} -->