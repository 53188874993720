<script>
    import arrow from '../../../../img/icons/arrow.svg?raw';
    import { afterUpdate } from "svelte";
    import ItemCardFeatures from "./ItemCardFeatures.svelte";
	import { fade } from 'svelte/transition';
    import { quadOut } from 'svelte/easing';
    // import PopUp from '../../component/PopUp.svelte';

    export let item = {};
    export let index = 0;
    export let defaultTitle = 'small';
    export let rounded = true;
    export let isLast = false;

    let tag = 'div';
    let popup;
    let card;
    let popupOpen = false;
    let textElement;

    if(item.link){
        tag = 'a'
    }
    afterUpdate(()=>{
        card.href = item.link;
        if(tag == 'div' && textElement){
            textElement.style = 'overflow-y:auto';
            if(textElement.scrollHeight > textElement.clientHeight){
                popup = true;
            }
            textElement.style = 'overflow-y:none';
        }
    });
</script>

<svelte:element 
    this={tag} 
    class="card tile_{item.tile ? item.tile: defaultTitle}" 
    class:last={isLast}
    class:popup
    class:rounded 
    style:order={item.sort}
    bind:this={card}
    in:fade={{delay:(index+1)*100, duration:700, easing:quadOut}}
    on:click={()=>{popupOpen = true}}
>
    <div class="card_image_wrap">
        <div class="card_image">
            {#if item.video}
                <video src="/resources{item.video}" muted loop autoplay></video>
            {:else}
                <img src="/resources{item.image}" alt={item.name} loading="lazy">
            {/if}
        </div>
        <div class="card_features">
            <ItemCardFeatures features={item.features}/>
        </div>
    </div>
    <div class="card_text_wrap">
        <p class="card_name">
            {item.name}
            {#if item.tile != "small" && item.link}
                {@html arrow}
            {/if}
        </p>
        {#if item.text}
            <p class="card_text" bind:this={textElement}>
                {item.text}
            </p>
        {/if}
    </div>
</svelte:element>
{#if popup}
    <!-- dynamic import -->
    {#await import('../../component/PopUp.svelte') then Module}
        {@const PopUp = Module.default}
        <PopUp bind:open={popupOpen}>
            <div class="popupDetail">
                <p class="popupHeader">
                    {item.name}
                </p>
                <p class="popupText">
                    {item.text}
                </p>
            </div>
        </PopUp>
    {/await}
{/if}
<style>
    .popupDetail{
        max-width: 420px;
        font-size: 16px;
    }
    .popupHeader, .popupText{
        line-height: normal;
        margin: 0;
    }
    .popupHeader{
        letter-spacing: 0.284px; 
        font-weight: 700; 
        text-transform: uppercase; 
        margin: * * 20px;
    }
</style>